<template>
  <div class="coianter">
    <div class="idteail">
      <div class="idtname">注册信息</div>
      <div class="back"  @click="goback()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="table">
      <el-form
        ref="form"
        :label-position="labelPosition"
        :model="form"
        label-width="150px"
      >
        <el-row :gutter="50">
          <el-col :span="12">
            <div class="grid-content bg-purple">
              <div class="gezi name_input">
                <el-form-item
                  label="项目名称"
                  prop="name"
                  :rules="{
                    required: true,
                    message: '请填写项目名称',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item
                  label="所属地区"
                  prop="city"
                  :rules="{
                    required: true,
                    message: '请填写所属地区',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="form.city"
                    placeholder="请选择所属地区"
                    @change="cityChange"
                  >
                    <el-option
                      v-for="item in cityOptions"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div class="geziright">
                  <el-form-item
                    label="所属区县"
                    prop="county"
                    :rules="{
                      required: true,
                      message: '请填写所属区县',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-model="form.county"
                      placeholder="请选择所属区县"
                    >
                      <el-option
                        v-for="item in countyOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item
                  label="建筑面积（m²）"
                  prop="building_area"
                  :rules="{
                    required: true,
                    message: '请填写建筑面积（m²）',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    @keyup.native="keyupEvent($event, 1)"
                    v-model="form.building_area"
                    placeholder="保留2位小数"
                  ></el-input>
                </el-form-item>
                <div class="geziright">
                  <el-form-item
                    label="占地面积（m²）"
                    prop="area"
                    :rules="{
                      required: true,
                      message: '请填写占地面积（m²）',
                      trigger: 'blur',
                    }"
                  >
                    <el-input
                      @keyup.native="keyupEvent($event, 2)"
                      v-model="form.area"
                      placeholder="保留2位小数"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item
                  label="测评阶段"
                  prop="stage"
                  :rules="{
                    required: true,
                    message: '请填写测评阶段',
                    trigger: 'blur',
                  }"
                >             
                  <el-radio-group v-model="form.stage">
                    <el-radio :label="1">建筑能效测评</el-radio>
                    <el-radio :label="2">建筑能效实测评估</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="测评单位"
                  prop="test_company"
                  :rules="{
                    required: true,
                    message: '请填写测评单位',
                    trigger: 'blur',
                  }"
                >
                  <span v-if="form.fname">{{ form.fname }}</span>
                  <span v-else>{{ form.test_company }}</span>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="测评联系人"
                  prop="test_contact"
                  :rules="{
                    required: true,
                    message: '请填写测评联系人',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.test_contact"></el-input>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="测评联系人电话"
                  prop="test_tel"
                  :rules="{
                    required: true,
                    message: '请填写测评联系人电话',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.test_tel"></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item
                  label="测评时间"
                  prop="test_time"
                  :rules="{
                    required: true,
                    message: '请填写测评时间',
                    trigger: 'blur',
                  }"
                >
                  <el-date-picker
                    v-model="form.test_time"
                    placeholder="选择日期"
                    type="date"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item label="上传能效测评报告 ">
                  <el-upload
                    :file-list="fileList"
                    :on-success="fileUploadSuccess"
                    action="https://api.gbservice.cn/api/public/bupload/"
                    class="upload-demo"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <span slot="tip" class="el-upload__tip">
                      选择上传文件
                    </span>
                  </el-upload>
                </el-form-item>
                <!-- <div class="fileright">
                  承诺函模板点击下载
                </div> -->
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
              <div class="gezi name_input">
                <el-form-item
                  label="项目地址"
                  prop="address"
                  :rules="{
                    required: true,
                    message: '请填写项目地址',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.address"></el-input>
                </el-form-item>
              </div>
              <div class="gezi">
                <el-form-item
                  label="建筑类型"
                  prop="building_type"
                  :rules="{
                    required: true,
                    message: '请填写建筑类型',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="form.building_type"
                    placeholder="请选择建筑类型"
                  >
                    <el-option label="公共建筑" :value="1"></el-option>
                    <el-option label="居住建筑" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <div class="geziright">
                  <el-form-item
                    label="建筑功能"
                    prop="building_ability"
                    :rules="{
                      required: true,
                      message: '请填写建筑功能',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-if="form.building_type == 1"
                      v-model="form.building_ability"
                      placeholder="请选择建筑功能"
                    >
                      <el-option
                        v-for="item in weatherOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>

                    <el-select
                      v-else
                      v-model="form.building_ability"
                      placeholder="请选择建筑功能"
                    >
                      <el-option
                        v-for="item in juzhuOptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item
                  label="层数"
                  prop="floor"
                  :rules="{
                    required: true,
                    message: '请填写层数',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="form.floor"
                    placeholder="实例：地上3层，地下3层"
                  ></el-input>
                </el-form-item>
                <div class="geziright">
                  <el-form-item
                    label="气候区域"
                    prop="weather"
                    :rules="{
                      required: true,
                      message: '请填写气候区域',
                      trigger: 'blur',
                    }"
                  >
                    <el-select
                      v-model="form.weather"
                      placeholder="请选择气候区域"
                    >
                      <el-option label="夏热冬冷" value="1"></el-option>
                      <el-option label="寒冷" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="gezi">
                <el-form-item
                  label="测评星级"
                  prop="stars"
                  :rules="{
                    required: true,
                    message: '请填写测评星级',
                    trigger: 'blur',
                  }"
                >
                  <el-radio-group v-model="form.stars">
                    <el-radio :label="1"
                      ><i class="el-icon-star-on"></i
                    ></el-radio>
                    <el-radio :label="2"
                      ><i class="el-icon-star-on"></i
                      ><i class="el-icon-star-on"></i
                    ></el-radio>
                    <el-radio :label="3"
                      ><i class="el-icon-star-on"></i
                      ><i class="el-icon-star-on"></i
                      ><i class="el-icon-star-on"></i
                    ></el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="建设单位"
                  prop="build_company"
                  :rules="{
                    required: true,
                    message: '请填写建设单位',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.build_company"></el-input>
                </el-form-item>
              </div>
               <div class="gezi name_input">
                <el-form-item
                  label="项目负责人"
                  prop="manger"
                  :rules="{
                    required: true,
                    message: '请填写项目负责人',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.manger"></el-input>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="项目联系人"
                  prop="contact"
                  :rules="{
                    required: true,
                    message: '请填写项目联系人',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.contact"></el-input>
                </el-form-item>
              </div>
             
              <div class="gezi name_input">
                <el-form-item
                  label="项目联系人电话"
                  prop="contact_tel"
                  :rules="{
                    required: true,
                    message: '请填写项目联系人电话',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.contact_tel"></el-input>
                </el-form-item>
              </div>
              <div class="gezi name_input">
                <el-form-item
                  label="能效测评报告编号"
                  prop="number"
                  :rules="{
                    required: true,
                    message: '请填写能效测评报告编号',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="form.number"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="comit">
      <div class="baocun">
        <el-button type="primary" @click="saveProject(21)">保存</el-button>
      </div>
      <div class="baocun">
        <el-button type="primary" @click="saveProject(20)">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  projectTableInfo,
  storeProject,
  userInfoApi,
} from "../../../../api/project";
import JiangSu from "../../../../assets/jiangsu.json";
// const validatePhone = (rule, value, callback) => {
//   if (value === "") {
//     callback(new Error("请输入手机号"));
//   } else {
//     if (!/^1[3456789]\d{9}$/.test(value)) {
//       callback(new Error("请输入正确的手机号"));
//     } else {
//       callback();
//     }
//   }
// };
export default {
  // 手机号验证

  data() {
    return {
      labelPosition: "right",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      form: {
        stars: 1,
        test_company: "",
      },
      cityOptions: JiangSu.city,
      countyOptions: [],
      //公共建筑
      weatherOptions: [
        {
          name: "办公建筑",
          id: 1,
        },
        {
          name: "商场建筑",
          id: 2,
        },
        {
          name: "宾馆饭店建筑",
          id: 3,
        },
        {
          name: "文化教育建筑",
          id: 4,
        },
        {
          name: "医疗卫生建筑",
          id: 5,
        },
        {
          name: "其他建筑",
          id: 6,
        },
      ],
      //居住建筑
      juzhuOptions: [
        {
          name: "住宅",
          id: 1,
        },
        {
          name: "其它",
          id: 2,
        },
      ],
    };
  },
  mounted() {
    let project_id = this.$route.query.project_id;
    if (project_id && project_id != "") {
      this.project_id = project_id;
      this.getInfo();
    }
    this.getUserInfo();
     this.getCache();
      this.onBlur();
    // let update = this.$route.query.update;
    // if (update){
    //   let form  = this.$route.query.form;
    //   if (form && form!=''){
    //     this.form = JSON.parse(form);
    //   }
    // }
  },
  methods: {
     // 返回
    goback(){
    this.$router.push('/user_mark/itemdeclare')
    },
     // 设置失去焦点事件
    onBlur(){
  let that =this;
   let formDOM = document.getElementById("form");
   let input = formDOM.getElementsByTagName("input");
   if (input && input.length > 0) {
        for (let i = 0; i < input.length; i++) {
          input[i].onblur = function () {
            that.saveCache();
          };
        }
      }
    },
    //自动保存填写的信息到缓存中
    saveCache() {
      if (this.toCache) {
        console.log("保存到缓存");
        if (this.form) {
          localStorage.setItem("itde_form", JSON.stringify(this.form));
        }
        if (this.fileList) {
          localStorage.setItem("itde_fileList", JSON.stringify(this.fileList));
        }
        if (this.photo) {
          localStorage.setItem("itde_photo", JSON.stringify(this.photo));
        }
      }
    },
    //清除缓存
    clearCache() {
      localStorage.removeItem("itde_form");
      localStorage.removeItem("itde_fileList");
      localStorage.removeItem("itde_photo");
    },
     //获得缓存中的信息
    getCache() {
      let form = localStorage.getItem("itde_form");
      if (form && form != "") {
        this.form = JSON.parse(form);
      }
      let fileList = localStorage.getItem("itde_fileList");
      if (fileList && fileList != "") {
        this.fileList = JSON.parse(fileList);
      }
      let photo = localStorage.getItem("itde_photo");
      if (photo && photo != "") {
        this.photo = JSON.parse(photo);
      }
    },
    keyupEvent(e, input) {
      console.log(input);
      e.target.value = e.target.value.replace(/[^\d.]/g, "");
      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
      e.target.value = e.target.value.replace(/^\./g, "0.");
      e.target.value = e.target.value.replace(
        /^\d*\.\d*\./g,
        e.target.value.substring(0, e.target.value.length - 1)
      );
      e.target.value = e.target.value.replace(/^0[^\\.]+/g, "0");
      e.target.value = e.target.value.replace(/^(\d+)\.(\d\d).*$/, "$1.$2");
      if (input == 1) {
        this.form.building_area = e.target.value;
        console.log(this.form.building_area);
      } else if (input == 2) {
        this.form.area = e.target.value;
      }
    },
    //获得主账号信息
    getUserInfo() {
      let params = {
        token: this.$store.state.token,
      };
      let that = this;
      userInfoApi(params).then((res) => {
        console.log(res);
        that.user = res.data;
        that.form.test_company = that.user.name;
        console.log(that.form.test_company);
      });
    },
    //获取详情
    getInfo() {
      let params = {
        id: this.project_id,
        token: this.$store.state.token,
      };
      let that = this;
      projectTableInfo(params).then((res) => {
        console.log(res);
        that.form = res.data;
        let file3 = [];
        let file2 = [];
        if (this.form.files && this.form.files.length > 0) {
          file3 = this.form.files
            .map((item) => {
              if (item.type == 3) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.fileList = file3;
        if (this.form.files && this.form.files.length > 0) {
          file2 = this.form.files
            .map((item) => {
              if (item.type == 2) {
                item.name = item.file;
                return item;
              }
            })
            .filter((d) => d);
        }
        this.photo = file2;
      });
    },
    //图片上传成功
    photo_success(re, file, fileList) {
      console.log(fileList);
    },
    fileUploadSuccess(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
    },
    //切换市
    cityChange(city) {
      for (let i = 0; i < this.cityOptions.length; i++) {
        if (this.cityOptions[i].name == city) {
          this.countyOptions = this.cityOptions[i].area;
          break;
        }
      }
    },

    //保存项目
    saveProject(status) {
      let params = this.form;
      params.token = this.$store.state.token;
      if (this.fileList && this.fileList.length > 0) {
        params.files = this.fileList.map((item) => {
          item.type = 3;
          return item;
        });
      }
      //去除多余字段 包含下面任意字段都报错
      params.zname = undefined;
      params.created_at = undefined;
      params.updated_at = undefined;
      params.log = undefined;
      params.jizhu = undefined;
      params.checks = undefined;
      params.detail = undefined;
      params.report = undefined;
      params.send_address = undefined;

      params.status = status;
      let that = this;
      storeProject(params)
        .then((res) => {
          console.log(res);
          that.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // handleChange(file, fileList) {
    //   this.fileList = fileList.slice(-3);
    // },
    handleRemove(file) {
      console.log(file, "2");
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "1");
    },
  },
};
</script>
<style scoped>
textarea {
  width: 99%;
  height: 40px;
  border:none;
  text-align: center;
  line-height: 40px;
  overflow: visible;
  font-size: 14px;
  word-break: break-all;
  background-color: #f4f4f4f4;
}
textarea::-webkit-scrollbar{
  display: none;
}
/deep/ .el-input {
  width: 250px;
}
</style>
<style lang="scss" scoped>
.coianter {
  background-color: #ffffff;
  // height: 100%;
}

.idteail {
  margin: 4px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #f4f4f4f4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .idtname {
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    margin-left: 63px;
  }

  .back {
    margin-right: 23px;
    font-size: 20px;
    cursor: pointer;
  }
}

.gezi {
  // margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .geziright {
    // margin-left: 20px;
  }

  .fileright {
    display: flex;
    flex-direction: row-reverse;
    color: #3086fb;
    font-size: 14px;
    cursor: pointer;
  }
}

.table {
  margin: 34px 100px;
}

.comit {
  padding: 76px 0;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .baocun {
    margin: 0 25px;
  }
}
.name_input {
  width: 100%;
}
.name_input > .el-form-item {
  width: 100%;
}
.name_input > .el-form-item .el-input {
  width: 100%;
}
</style>
