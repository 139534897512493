var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coianter"},[_c('div',{staticClass:"idteail"},[_c('div',{staticClass:"idtname"},[_vm._v("注册信息")]),_c('div',{staticClass:"back",on:{"click":function($event){return _vm.goback()}}},[_c('i',{staticClass:"el-icon-close"})])]),_c('div',{staticClass:"table"},[_c('el-form',{ref:"form",attrs:{"label-position":_vm.labelPosition,"model":_vm.form,"label-width":"150px"}},[_c('el-row',{attrs:{"gutter":50}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"grid-content bg-purple"},[_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"项目名称","prop":"name","rules":{
                  required: true,
                  message: '请填写项目名称',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"所属地区","prop":"city","rules":{
                  required: true,
                  message: '请填写所属地区',
                  trigger: 'blur',
                }}},[_c('el-select',{attrs:{"placeholder":"请选择所属地区"},on:{"change":_vm.cityChange},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}},_vm._l((_vm.cityOptions),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item.name}})}),1)],1),_c('div',{staticClass:"geziright"},[_c('el-form-item',{attrs:{"label":"所属区县","prop":"county","rules":{
                    required: true,
                    message: '请填写所属区县',
                    trigger: 'blur',
                  }}},[_c('el-select',{attrs:{"placeholder":"请选择所属区县"},model:{value:(_vm.form.county),callback:function ($$v) {_vm.$set(_vm.form, "county", $$v)},expression:"form.county"}},_vm._l((_vm.countyOptions),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1)],1)],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"建筑面积（m²）","prop":"building_area","rules":{
                  required: true,
                  message: '请填写建筑面积（m²）',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"placeholder":"保留2位小数"},nativeOn:{"keyup":function($event){return _vm.keyupEvent($event, 1)}},model:{value:(_vm.form.building_area),callback:function ($$v) {_vm.$set(_vm.form, "building_area", $$v)},expression:"form.building_area"}})],1),_c('div',{staticClass:"geziright"},[_c('el-form-item',{attrs:{"label":"占地面积（m²）","prop":"area","rules":{
                    required: true,
                    message: '请填写占地面积（m²）',
                    trigger: 'blur',
                  }}},[_c('el-input',{attrs:{"placeholder":"保留2位小数"},nativeOn:{"keyup":function($event){return _vm.keyupEvent($event, 2)}},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}})],1)],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"测评阶段","prop":"stage","rules":{
                  required: true,
                  message: '请填写测评阶段',
                  trigger: 'blur',
                }}},[_c('el-radio-group',{model:{value:(_vm.form.stage),callback:function ($$v) {_vm.$set(_vm.form, "stage", $$v)},expression:"form.stage"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("建筑能效测评")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("建筑能效实测评估")])],1)],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"测评单位","prop":"test_company","rules":{
                  required: true,
                  message: '请填写测评单位',
                  trigger: 'blur',
                }}},[(_vm.form.fname)?_c('span',[_vm._v(_vm._s(_vm.form.fname))]):_c('span',[_vm._v(_vm._s(_vm.form.test_company))])])],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"测评联系人","prop":"test_contact","rules":{
                  required: true,
                  message: '请填写测评联系人',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.test_contact),callback:function ($$v) {_vm.$set(_vm.form, "test_contact", $$v)},expression:"form.test_contact"}})],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"测评联系人电话","prop":"test_tel","rules":{
                  required: true,
                  message: '请填写测评联系人电话',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.test_tel),callback:function ($$v) {_vm.$set(_vm.form, "test_tel", $$v)},expression:"form.test_tel"}})],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"测评时间","prop":"test_time","rules":{
                  required: true,
                  message: '请填写测评时间',
                  trigger: 'blur',
                }}},[_c('el-date-picker',{attrs:{"placeholder":"选择日期","type":"date","value-format":"yyyy-MM-dd"},model:{value:(_vm.form.test_time),callback:function ($$v) {_vm.$set(_vm.form, "test_time", $$v)},expression:"form.test_time"}})],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"上传能效测评报告 "}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"file-list":_vm.fileList,"on-success":_vm.fileUploadSuccess,"action":"https://api.gbservice.cn/api/public/bupload/"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('span',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 选择上传文件 ")])],1)],1)],1)])]),_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"grid-content bg-purple-light"},[_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"项目地址","prop":"address","rules":{
                  required: true,
                  message: '请填写项目地址',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"建筑类型","prop":"building_type","rules":{
                  required: true,
                  message: '请填写建筑类型',
                  trigger: 'blur',
                }}},[_c('el-select',{attrs:{"placeholder":"请选择建筑类型"},model:{value:(_vm.form.building_type),callback:function ($$v) {_vm.$set(_vm.form, "building_type", $$v)},expression:"form.building_type"}},[_c('el-option',{attrs:{"label":"公共建筑","value":1}}),_c('el-option',{attrs:{"label":"居住建筑","value":2}})],1)],1),_c('div',{staticClass:"geziright"},[_c('el-form-item',{attrs:{"label":"建筑功能","prop":"building_ability","rules":{
                    required: true,
                    message: '请填写建筑功能',
                    trigger: 'blur',
                  }}},[(_vm.form.building_type == 1)?_c('el-select',{attrs:{"placeholder":"请选择建筑功能"},model:{value:(_vm.form.building_ability),callback:function ($$v) {_vm.$set(_vm.form, "building_ability", $$v)},expression:"form.building_ability"}},_vm._l((_vm.weatherOptions),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item.id}})}),1):_c('el-select',{attrs:{"placeholder":"请选择建筑功能"},model:{value:(_vm.form.building_ability),callback:function ($$v) {_vm.$set(_vm.form, "building_ability", $$v)},expression:"form.building_ability"}},_vm._l((_vm.juzhuOptions),function(item){return _c('el-option',{key:item.name,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"层数","prop":"floor","rules":{
                  required: true,
                  message: '请填写层数',
                  trigger: 'blur',
                }}},[_c('el-input',{attrs:{"placeholder":"实例：地上3层，地下3层"},model:{value:(_vm.form.floor),callback:function ($$v) {_vm.$set(_vm.form, "floor", $$v)},expression:"form.floor"}})],1),_c('div',{staticClass:"geziright"},[_c('el-form-item',{attrs:{"label":"气候区域","prop":"weather","rules":{
                    required: true,
                    message: '请填写气候区域',
                    trigger: 'blur',
                  }}},[_c('el-select',{attrs:{"placeholder":"请选择气候区域"},model:{value:(_vm.form.weather),callback:function ($$v) {_vm.$set(_vm.form, "weather", $$v)},expression:"form.weather"}},[_c('el-option',{attrs:{"label":"夏热冬冷","value":"1"}}),_c('el-option',{attrs:{"label":"寒冷","value":"2"}})],1)],1)],1)],1),_c('div',{staticClass:"gezi"},[_c('el-form-item',{attrs:{"label":"测评星级","prop":"stars","rules":{
                  required: true,
                  message: '请填写测评星级',
                  trigger: 'blur',
                }}},[_c('el-radio-group',{model:{value:(_vm.form.stars),callback:function ($$v) {_vm.$set(_vm.form, "stars", $$v)},expression:"form.stars"}},[_c('el-radio',{attrs:{"label":1}},[_c('i',{staticClass:"el-icon-star-on"})]),_c('el-radio',{attrs:{"label":2}},[_c('i',{staticClass:"el-icon-star-on"}),_c('i',{staticClass:"el-icon-star-on"})]),_c('el-radio',{attrs:{"label":3}},[_c('i',{staticClass:"el-icon-star-on"}),_c('i',{staticClass:"el-icon-star-on"}),_c('i',{staticClass:"el-icon-star-on"})])],1)],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"建设单位","prop":"build_company","rules":{
                  required: true,
                  message: '请填写建设单位',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.build_company),callback:function ($$v) {_vm.$set(_vm.form, "build_company", $$v)},expression:"form.build_company"}})],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"项目负责人","prop":"manger","rules":{
                  required: true,
                  message: '请填写项目负责人',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.manger),callback:function ($$v) {_vm.$set(_vm.form, "manger", $$v)},expression:"form.manger"}})],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"项目联系人","prop":"contact","rules":{
                  required: true,
                  message: '请填写项目联系人',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.contact),callback:function ($$v) {_vm.$set(_vm.form, "contact", $$v)},expression:"form.contact"}})],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"项目联系人电话","prop":"contact_tel","rules":{
                  required: true,
                  message: '请填写项目联系人电话',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.contact_tel),callback:function ($$v) {_vm.$set(_vm.form, "contact_tel", $$v)},expression:"form.contact_tel"}})],1)],1),_c('div',{staticClass:"gezi name_input"},[_c('el-form-item',{attrs:{"label":"能效测评报告编号","prop":"number","rules":{
                  required: true,
                  message: '请填写能效测评报告编号',
                  trigger: 'blur',
                }}},[_c('el-input',{model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}})],1)],1)])])],1)],1)],1),_c('div',{staticClass:"comit"},[_c('div',{staticClass:"baocun"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveProject(21)}}},[_vm._v("保存")])],1),_c('div',{staticClass:"baocun"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveProject(20)}}},[_vm._v("提交")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }